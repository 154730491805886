import React, { Fragment } from 'react';
import { Lock60x72, Wrench72x72 } from '@bamboohr/grim';
import { IconV2, LayoutBox, Headline, useTheme } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ExternalLink, Message } from '@bamboohr/utils/lib/message';
import LockOutInterstitial from '../LockOutInterstitial';

const FraudLockOut = ({ isPotentialFraud }) => {
	const emailAddress = isPotentialFraud ? 'success@bamboohr.com' : 'support@bamboohr.com';
	const { palette } = useTheme();

	return (
		<LockOutInterstitial>
			<Fragment>
				<LayoutBox marginBottom={1.5} marginTop={3.5}>
					{ isPotentialFraud ? ifFeature('encore', <IconV2 color='primary-strong' name='lock-regular' size={60} />, <Lock60x72 fill={palette.primary.main} />) : ifFeature('encore', <IconV2 color='primary-strong' name='wrench-regular' size={60} />, <Wrench72x72 fill={palette.primary.main} />) }
				</LayoutBox>
				<Headline color='neutral-strong' justify='center' size='small'>{ $.__('We\'re working on it.') }</Headline>
				<LayoutBox color='primary-strong' marginBottom={2} marginTop={1.5}>
					<Message
						link={ ExternalLink(`mailto:${ emailAddress }`) }
						text={ $.__('Sorry, this site is temporarily unavailable. \n Questions? [Email us].') }
					/>
				</LayoutBox>
			</Fragment>
		</LockOutInterstitial>
	);
};

FraudLockOut.defaultProps = {
	isPotentialFraud: false,
};

export default FraudLockOut;
