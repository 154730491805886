import { getPoHost } from 'base/_components/micro-frontend.react/resources/hooks/use-resource';
import { resolveResourceUrl } from 'base/_components/micro-frontend.react/resources/resolve-resource-url';

let salesforceChatLoaded = false;

export function checkAvailableRepeatedly(checkAvailable, milliseconds) {
	checkAvailable();
	window.setTimeout(() => {
		checkAvailableRepeatedly(checkAvailable, milliseconds);
	}, milliseconds);
}
export function toggleChatAvailability(available, chatClient, targetElementClass, toggleRootClass) {
	const $targetElement = $(`.${targetElementClass}`);
	if (available && chatClient.startLink !== 'undefined') {
		$targetElement.removeClass(`${toggleRootClass}--hide`);
		$targetElement.on('click', function () {
			chatClient.startLink();
		});
	} else {
		$targetElement.off('click');
		$targetElement.addClass(`${toggleRootClass}--hide`);
	}
}

export function loadSnapEngage(widgetId = '19325311-7882-4043-b503-b08e69b88da8') {
	return new Promise((resolve, reject) => {
		let done = false;

		let se = document.getElementById('snapengage');

		if (!se) {
			se = document.createElement('script');
			se.id = 'snapengage';
			se.type = 'text/javascript';
			se.async = true;
			se.src = `//storage.googleapis.com/code.snapengage.com/js/${widgetId}.js`;

			let s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(se, s);
		}

		const handleLoad = function () {
			if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
				done = true;
				window.SnapEngage.hideButton();
				resolve(window.SnapEngage);
			}
		};

		se.addEventListener('load', handleLoad);
		se.addEventListener('readystatechange', handleLoad);
	});
}

export const wireSnapEngage = (targetElementClass, toggleRootClass) => {
	const menuToggle = document.querySelector('.js-help-center-button');
	if (menuToggle) {
		menuToggle.addEventListener('click', async () => {
			const client = await getSnapEngageClient('19325311-7882-4043-b503-b08e69b88da8');

			client.getAgentStatusAsync((online) => {
				toggleChatAvailability(online, client, targetElementClass, toggleRootClass);
			});
		});
	}
};

export const launchSalesforceChat = () => {
	const poHost = getPoHost();
	const poManifestUrl = `${poHost}/manifest.json`;
	return fetch(poManifestUrl)
		.then((response) => response.json())
		.then((manifest) => {
			const sfLauncherScript = manifest['salesforce-chat.js'];
			return new Promise((resolve) => {
				const tag = document.createElement('script');
				tag.src = sfLauncherScript.startsWith('http') ? sfLauncherScript : resolveResourceUrl(poHost, sfLauncherScript);
				tag.async = true;
				tag.onload = () => resolve();
				document.head.appendChild(tag);
			});
		})
		.catch((error) => {
			console.warn(error);
		});
};

export const wireSalesforceChat = (targetElementClass, toggleRootClass) => {
	const isChatting = window.sessionStorage.getItem('salesforce-chat-active');
	if (isChatting) {
		launchSalesforceChat();
	}
	const menuToggle = document.querySelector('.js-help-center-button');
	if (!menuToggle || !window.IS_WITHIN_SALESFORCE_CHAT_BUSINESS_HOURS) {
		return;
	}

	if (!salesforceChatLoaded) {
		// By default the menu item show. Still need that for snap engage while we have it
		toggleChatAvailability(false, {}, targetElementClass, toggleRootClass);
	}

	menuToggle.addEventListener('click', async () => {
		if (!salesforceChatLoaded) {
			salesforceChatLoaded = true;
			window.addEventListener('SALESFORCE_CHAT_READY', function (data) {
				const isChatAvailable = data.detail.isAgentAvailable;
				toggleChatAvailability(
					isChatAvailable,
					{
						startLink: () => {
							window.embedded_svc.bootstrapEmbeddedService();
						},
					},
					targetElementClass,
					toggleRootClass
				);
			});
			await launchSalesforceChat();
		}
	});
};

export const getSnapEngageClient = (widgetId) => {
	if (window.SnapEngage) {
		return new Promise((resolve) => {
			window.SnapEngage.switchWidget(widgetId, () => {
				resolve(window.SnapEngage);
			});
		});
	} else {
		return loadSnapEngage(widgetId).then((snapEngage) => {
			return snapEngage;
		});
	}
};
