import { render } from 'base/wrapped-render';

import {
	CompanyData,
} from './company-data.react';
import {
	Modals as ModalConstants,
} from './constants';

const reactRoot = document.createElement('div');

document.body.appendChild(reactRoot);

export function renderCompanyData(props) {
	render(
		<CompanyData { ...props } />,
		reactRoot
	);
}

export const Modals = ModalConstants;

export const clearDemoData = () => {
	renderCompanyData({
		defaultModal: ModalConstants.CLEAR_DEMO_DATA_MODAL,
		onDefaultModalClose: () => renderCompanyData({ defaultModal: undefined }),
	});
};