import {
	Message,
} from '@bamboohr/utils/lib/message';

/* @startCleanup encore */
import {
	Headline as HeadlineLegacy,
} from '../../headline.react';
/* @endCleanup encore */
import { CrossFadeTransition } from './cross-fade-transition.react';
import React from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '@bamboohr/fabric';

export function Step1(props) {
	const {
		isProcessing = false,
	} = props;

	const waiting = (
		<div>
			<h3>{ $.__(`We’re wiping the slate clean.`) }</h3>

			<Message
				text={ $.__('Removing demo data will take a few seconds.\nPlease bear with us.') }
			/>
		</div>
	);

	const warning = (
		<div>
			<h3>{ $.__('Ready for a fresh start?') }</h3>

			<Message
				text={ $.__('This will remove all of the demo data in this account along with anything that you may have added. Are you sure that you want to remove this information?') }
			/>
		</div>
	);

	return (
		<div style={ { textAlign: 'center' } }>
			{ifFeature('encore', <IconV2 color='primary-strong' name='broom-wide-solid' size={55} />, <HeadlineLegacy hasSeparator={ false } icon="fab-broom-51x55" />)}
			<CrossFadeTransition	
				active={ isProcessing ? 'waiting' : 'warning' }
				stages={
					{
						warning,
						waiting,
					}
				}
			/>
		</div>
	);
}
