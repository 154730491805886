import {
	useCallback,
	useEffect,
	useState,
} from 'react';

import {
	showSlidedown,
} from 'Alerts.mod';
import {
	acceptDataProcessingAgreement,
	completeDataProcessingAgreementLater,
	declineDataProcessingAgreement,
	getLatestDataProcessingAgreement,
} from 'data-processing-agreement.mod';
import {
	StepIndicator,
} from 'step-indicator.react';

import {
	Step1,
} from './components/step-1.react';
import {
	Step2,
} from './components/step-2.react';
import {
	getTotalSteps,
} from './utils';
import {
	COMPLETE_LATER_ACTION_TEXT,
} from '../../constants';
import {
	clearDemoData,
} from '../../service';

export function useStepper(totalSteps, { onComplete = () => {} }) {
	const [currentStep, setCurrentStep] = useState(1);
	const [data, setData] = useState();

	return {
		data,
		currentStep,
		next: (stepData) => {
			if (currentStep === totalSteps) {
				onComplete(data);
			} else {
				setCurrentStep(currentStep + 1);
			}
			setData(stepData);
		},
		totalSteps,
	};
}

export function useClearDemoDataStep(stepNumber, { next, currentStep, totalSteps }) {
	const [isProcessing, setIsProcessing] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [latestAgreement, setLatestAgreement] = useState(null);

	useBeforeUnload(isProcessing && !isDone);

	/*
		Disabling the warning for not listing a "next" as a dependency because we don't care if has changed.
		In fact, listing "next" as a dependency will cause an infinite loop here because it always changes.
	*/

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (isDone) {
			next({ latestAgreement });
		}
	}, [isDone, latestAgreement]);
	/* eslint-enable react-hooks/exhaustive-deps */

	if (stepNumber !== currentStep) {
		return {};
	}

	return {
		content: (
			<Step1 isProcessing={ isProcessing || isDone } />
		),
		isProcessing: isProcessing || isDone,
		primaryAction: () => {
			setIsProcessing(true);

			setTimeout(async () => {
				try {
					await clearDemoData();
				} catch (e) {
					setIsProcessing(false);
					showSlidedown($.__(`Uh oh. Looks like your demo data didn’t clear. Please try again, or call us at 866-387-9595 for help.`), 'error');
					return;
				}

				try {
					const _latestAgreement = await getLatestDataProcessingAgreement();

					if (!_latestAgreement) {
						throw new Error('No data processing agreement is available');
					}

					setLatestAgreement(_latestAgreement);

					setIsProcessing(false);
					setIsDone(true);
				} catch (e) {
					showSlidedown('Uh oh! It looks like the next step didn’t load as expected. Please reload this page.', 'error');
				}
			}, 3e3);
		},
		primaryActionText: $.__('Clear Data'),
		title: (
			<div>
				{ $.__('Clear Demo Data') }
				{
					totalSteps > 1 ? (
						<StepIndicator
							currentStep={ currentStep }
							hasSeparator={ !isProcessing }
							totalStepCount={ totalSteps }
						/>
					) : null
				}
			</div>
		),
	};
}

export function useDpaStep(stepNumber, { data = {}, next, currentStep, totalSteps }) {
	const {
		latestAgreement = {},
	} = data;

	const [agreementStatus, setAgreementStatus] = useState({
		isAccepted: false,
		hasEmployees: false,
	});

	const [isProcessing, setIsProcessing] = useState(false);

	const canUsePrimaryAction = agreementStatus.isAccepted || !agreementStatus.hasEmployees;

	const endpointOptions = { setSessionMessage: true };

	if (stepNumber !== currentStep || !data || !latestAgreement) {
		return {};
	}

	return {
		alternativeAction: null,
		content: (
			<Step2
				data={ agreementStatus }
				isProcessing={ isProcessing }
				onChange={ status => setAgreementStatus(status) }
			/>
		),
		contentHasMaxHeight: false,
		hasCloseButton: false,
		isProcessing,
		primaryAction: canUsePrimaryAction ? async () => {
			setIsProcessing(true);

			try {
				if (agreementStatus.isAccepted) {
					await acceptDataProcessingAgreement(latestAgreement.id, endpointOptions);
				} else {
					await declineDataProcessingAgreement(latestAgreement.id, endpointOptions);
				}

				next();
			} catch (e) {
				setIsProcessing(false);
				showSlidedown($.__('Oops. Something went wrong. Please try again.'), 'error');
			}
		} : null,
		primaryActionText: $.__('Done'),
		secondaryAction: async () => {
			setIsProcessing(true);
			try {
				await completeDataProcessingAgreementLater(latestAgreement.id, endpointOptions);
				next();
			} catch (e) {
				setIsProcessing(false);
				showSlidedown($.__('Oops. Something went wrong. Please try again.'), 'error');
			}
		},
		secondaryActionText: COMPLETE_LATER_ACTION_TEXT,
		title: (
			<div>
				{ $.__('GDPR Compliance') }
				{
					totalSteps > 1 ? (
						<StepIndicator
							currentStep={ currentStep }
							hasSeparator={ false }
							totalStepCount={ totalSteps }
						/>
					) : null
				}
			</div>
		),
	};
}

export function useBeforeUnload(isProcessing) {
	const handleBeforeUnload = useCallback((event) => {
		event.preventDefault();
		event.returnValue = $.__('Are you sure you want to leave now?');
	}, []);

	useEffect(() => {
		if (isProcessing) {
			window.addEventListener('beforeunload', handleBeforeUnload);
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		}

	}, [handleBeforeUnload, isProcessing]);
}

export function useLoader(loader, when) {
	const [value, setValue] = useState();
	const [status, setStatus] = useState(false);

	useEffect(() => {
		const load = async () => {
			setStatus('loading');

			try {
				setValue(await loader());
				setStatus('completed');
			} catch (e) {
				setStatus('failed');
			}
		};

		if (when) {
			load();
		}

	}, [when]);

	return {
		value,
		status,
	};
}

export function useTotalSteps(isOpen) {
	const {
		value: currentDpa,
		status,
	} = useLoader(async () => {
		try {
			return (
				await getLatestDataProcessingAgreement()
			);
		} catch (e) {
			console.error('Failed to get latest Data Processing Agreement while clearing demo data!');
			throw e;
		}
	}, isOpen);

	return getTotalSteps(isOpen, currentDpa, status);
}
