import { render } from 'base/wrapped-render';
import Ctrl, { onReady } from 'ctrl.deco';
import { getJsonData } from 'Data.util';
import { uniqueId } from 'lodash';
import { PoMicroFrontend } from 'micro-frontend.react';
import { FraudLockOut } from './components';

@Ctrl('/settings/account/suspended')
class SuspendedCtrl {
	@onReady()
	_readyHandler() {
		const root = document.getElementById('suspendedAccountRoot');

		if (root) {
			const data = getJsonData('#suspendedAccount_json');
			render(
				<PoMicroFrontend
					customClassNamePrefix={uniqueId('suspension-lock-out')}
					route='/billing/suspension-lock-out'
					suspendedDetails={data}
				/>,
				root
			);
		}
	}
}

@Ctrl('/settings/account/temporarily_suspended')
class TemporarySuspendedCtrl {
	@onReady()
	_readyHandler() {
		const root = document.getElementById('suspendedAccountFraudRoot');

		if (root) {
			const data = getJsonData('#suspendedAccount_fraud_json');
			render(<FraudLockOut {...data} />, root);
		}
	}
}
