import { colors } from '@bamboohr/fabric/dist/definitions/json/colors.json';

export default {
	background: (showBackground) => {
		return {
			height: '50%',
			width: '100%',
			position: 'absolute',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundImage: showBackground ? 'url("/images/suspended/company_culture.png")' : 'none',
			top: '0',
		};
	},
	view: {
		backgroundColor: `${ colors.gray1 }`,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	content: {
		margin: '0 auto',
		maxWidth: '475px',
		textAlign: 'center',
		whiteSpace: 'pre-line',
	},
	message: {
		color: `${ colors.gray7 }`,
	},
};
