import Ctrl, {
	onAction,
	onReady,
} from 'ctrl.deco';
import {
	initGlobalModal,
} from 'global-modal';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import './styles.styl';

@Ctrl([
    '/(login|logged_out).php',
    '/auth/(login|logout)*',
])
class LoginDeprecatedBrowserCtrl {
	@onReady()
	_onReady() {
		initGlobalModal();
	}

	@onAction('more-info')
	_onMoreInfoClick(e, ctrl) {
		ctrl.openModal();
	}

	openModal() {
		const html = document.getElementById('DeprecatedBrowserModalTmpl').innerHTML;

		window.BambooHR.Modal.setState({
			dangerousContent: html,
			title: $.__('BambooHR and Browsers'),
			onClose: () => window.BambooHR.Modal.setState({isOpen: false}),
			primaryActionText: $.__('Done'),
			primaryAction: () => window.BambooHR.Modal.setState({isOpen: false}),
			alternativeAction: null,
			isOpen: true,
			headline: ifFeature('encore', $.__('BambooHR works best on modern browsers.')),
			icon: ifFeature('encore', 'browser-regular'),
			headerType: ifFeature('encore', 'text'),
		});
	}
}
