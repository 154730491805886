import {
	ajax,
} from '@utils/ajax';

import {
	showSlidedown,
} from 'Alerts.mod';

import {
	ForgotPasswordResponse,
} from './types';

export const loginErrors = {
	noEmail: $.__('Oops! Please enter your email address in order to log in.'),
	none: $.__('Oops! Please enter your email address and password in order to log in.'),
	noPass: $.__('Oops! Please enter your password in order to log in.'),
};

enum METHODS {
	POST = 'post',
	GET = 'get'
}

export function isInvalidLogin(emailVal: string, passwordVal: string): boolean {
	if (emailVal.length === 0 && passwordVal.length === 0) {
		showSlidedown(loginErrors.none, 'error');
		return false;
	}
	if (emailVal.length === 0) {
		showSlidedown(loginErrors.noEmail, 'error');
		return false;
	}
	if (passwordVal.length === 0) {
		showSlidedown(loginErrors.noPass, 'error');
		return false;
	}
	return true;
}

export function handleFlip(flipContainer: HTMLDivElement, forgotButton: HTMLButtonElement): void {
	const backFlipElement = document.querySelector<HTMLDivElement>('.flip-container .back');
	const frontFlipElement = document.querySelector<HTMLDivElement>('.flip-container .front');
	const forgotEmailInput = backFlipElement.querySelector<HTMLInputElement>('#femail');
	const loginEmailInput = frontFlipElement.querySelector<HTMLInputElement>('#lemail');
	[backFlipElement, frontFlipElement].forEach((element: HTMLDivElement) => {
		if (element.classList.contains('forgot')) {
			element.classList.remove(('forgot'));
		}
	});
	if (forgotButton.classList.contains('ffront')) {
		flipContainer.classList.add('flip');
		if (loginEmailInput?.value && forgotEmailInput) {
			forgotEmailInput.value = loginEmailInput.value;
		}
		if (forgotEmailInput) {
			forgotEmailInput.focus();
		}
	} else if (forgotButton.classList.contains('fback')) {
		flipContainer.classList.remove('flip');
		if (forgotEmailInput?.value && loginEmailInput) {
			loginEmailInput.value = forgotEmailInput.value;
		}
		if (loginEmailInput) {
			loginEmailInput.focus();
		}
	}
}

export function handleForgotPasswordSubmit(flipContainer: HTMLDivElement, isResellerLoginPage: boolean) {
	return function handleForgotPassword(event: Event | KeyboardEvent): void {
		const target = event.target as HTMLElement;
		if (!target.closest('#btnForgotSubmit')) {
			return;
		}
		if (event instanceof KeyboardEvent) {
			if (event.type === 'keydown' && event.key !== '13') {
				return;
			}
		}
		event.preventDefault();
		const forgotEmailInput = document.querySelector<HTMLInputElement>('#femail');
		submitForgotPassword(flipContainer, forgotEmailInput, isResellerLoginPage);
	};
}

export function submitForgotPassword(flipContainer: HTMLDivElement, forgotEmailInput: HTMLInputElement, isResellerLoginPage: boolean): void {
	const forgotEmailInputValue = forgotEmailInput.value.trim();
	if (forgotEmailInputValue === '') {
		return;
	}

	let endpoint = '/ajax/forgot_password.php';
	let forgotPasswordData;
	let method = METHODS.POST;

	if (isResellerLoginPage) {
		endpoint = `/reset_password/${ forgotEmailInputValue }`;
		method = METHODS.GET;
	} else {
		forgotPasswordData = new FormData();
		forgotPasswordData.append('username', forgotEmailInputValue);
	}

	ajax.request<ForgotPasswordResponse>({
		data: forgotPasswordData,
		method,
		responseType: 'json',
		url: endpoint,
	}).then(({ data = {} }) => {
		const {
			msg,
			type,
		} = data;
		if (type === 'success') {
			flipContainer.classList.remove('flip');
		}
		if (type && msg) {
			showSlidedown(msg, type);
		}
		forgotEmailInput.value = '';
		forgotEmailInput.focus();
	});
}
