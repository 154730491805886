export const COMPLETE_LATER_ACTION_TEXT = $.__('Complete Later');

export const DpaStatus = Object.seal({
	COMPLETE_LATER: 'complete_later',
	DECLINED: 'declined',
	NEEDS_PROMPT: 'needs_prompt',
	NEEDS_TO_SIGN: 'needs_to_sign',
	SIGNED: 'signed',
	UPDATE_PENDING: 'update_pending'
});

export const Modals = Object.seal({
	CONDITIONAL_DPA_MODAL: 'optionalDpaModal',
	CLEAR_DEMO_DATA_MODAL: 'clearDemoDataModal',
	REQUESTED_DPA_MODAL: 'requestedDpaModal',
	UPDATED_DPA_MODAL: 'updatedDpaModal'
});
