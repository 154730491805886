import React from 'react';
import { CardSize, Interstitial } from '@bamboohr/fabric';
import styles from './styles';

const LockOutInterstitial = ({ children, showBackground, style}) => {
	return (
		<div style={ { ...styles.view, ...style } }>
			<div style={ { ...styles.background(showBackground) } } />
			<Interstitial cardSize={ CardSize.SMALL } centerByCard={ true } overlay="nothing">
				<div style={ { ...styles.content } }>
					{ children }
				</div>
			</Interstitial>
			<div id="sessionMessage"></div>
		</div>
	);
};

LockOutInterstitial.defaultProps = {
	children: null,
	showBackground: false,
	style: {}
};

export default LockOutInterstitial;
