import {
	Fragment,
} from 'react';

import {
	ConditionalHeadline,
	ConditionalAgreement,
} from 'data-processing-agreement.mod';

export function Step2(props) {
	const {
		data = {},
		onChange,
	} = props;

	const {
		isAccepted,
		hasEmployees,
	} = data;

	return (
		<Fragment>
			<ConditionalHeadline />
			<ConditionalAgreement
				hasEmployees={ hasEmployees }
				isAccepted={ isAccepted }
				onHasEmployeesChange={
					(_hasEmployees) => {
						onChange({ hasEmployees: _hasEmployees, isAccepted });
					}
				}
				onIsAcceptedChange={
					(_isAccepted) => {
						onChange({ hasEmployees, isAccepted: _isAccepted });
					}
				}
			/>
		</Fragment>
	);
}
