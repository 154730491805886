import {
	DpaStatus,
} from '../../constants';

export function isDpaCompleted(dpa) {
	return dpa && (
		dpa.status === DpaStatus.SIGNED || dpa.status === DpaStatus.DECLINED
	);
}

export function getTotalSteps(isOpen, dpa, loaderStatus) {
	let totalSteps = 0;

	if (isOpen) {
		if (loaderStatus === 'failed' || (loaderStatus === 'completed' && isDpaCompleted(dpa))) {
			totalSteps = 1;
		} else if (loaderStatus !== 'loading') {
			totalSteps = 2;
		}
	}

	return totalSteps;
}
