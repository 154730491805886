import React, { ReactElement, useState } from 'react';
import { TextButton, Flex, makeStyles, StyledBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function LoginPassword({ showForgotPassword = true, inputId = 'password' }): ReactElement {
	const [passwordType, setPasswordType] = useState('password');
	const [passwordText, setPasswordText] = useState($.__('Show Password'));

	const updatePasswordType = () => {
		setPasswordType(passwordType === 'password' ? 'text' : 'password');
		setPasswordText(passwordText === $.__('Show Password') ? $.__('Hide Password') : $.__('Show Password'));
	};

	const useStyles = makeStyles(() => ({
		marginContainer: {
			marginBottom: ifFeature('encore', '32px', '26px'),
		},
	}));

	const styles = useStyles();

	return (
		<div className={showForgotPassword ? styles.marginContainer : ''}>
			<div className='fab-InputWrapper'>
				<input
					autoComplete='off'
					className={
						`fab-TextInput fab-TextInput--withNub fab-TextInput--width9 ${ifFeature('encore', 'fab-typography--medium', 'fab-TextInput--biggie')}`
					}
					id={inputId}
					name='password'
					placeholder={$.__('Password')}
					tabIndex={window.location.pathname.includes('self_onboarding') ? 0 : 4}
					type={passwordType}
				/>
				<span className='fab-TextInputNub'>
					<span className='fab-TextInputNub__icon'>
						<ba-icon encore-name="key-regular" encore-size="16" name='fab-key-18x18'></ba-icon>
					</span>
				</span>
			</div>
			<StyledBox encoreOnly={true} marginTop="4px">
				<Flex alignItems='center' justifyContent={showForgotPassword ? 'space-between' : 'flex-end'}>
					{showForgotPassword && (
						<button   className={
							`fab-TextButton fab-TextButton--muted ${ifFeature('encore', 'fab-TextButton--small', '')} ffront forgot`
						}  type='button'>
							{$.__('Forgot Password?')}
						</button>
					)}
					<TextButton color='secondary' onClick={updatePasswordType} size={ifFeature('encore', 'small')} type='button'>
						{passwordText}
					</TextButton>
				</Flex>
			</StyledBox>
		</div>
	);
}
export default LoginPassword;
