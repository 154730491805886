import {
	useEffect,
	useRef,
	useCallback,
} from 'react';

export function CrossFadeTransition(props) {
	const {
		stages = {},
		active,
	} = props;

	const wrapperRef = useRef();
	const stageRefs = useRef({});
	const previousStageRef = useRef();

	const handleTransitionEnd = useCallback((event) => {
		const wrapperElement = wrapperRef.current;

		if (event.propertyName === 'opacity' && event.target.style.opacity === 0) {
			event.target.style.display = 'block';
		}

		wrapperElement.style.height = '';
		wrapperElement.removeEventListener('transitionend', handleTransitionEnd);
	}, []);

	useEffect(() => {
		const oldActiveElement = previousStageRef.current;
		const newActiveElement = stageRefs.current[active];
		const wrapperElement = wrapperRef.current;

		wrapperElement.addEventListener('transitionend', handleTransitionEnd);

		if (oldActiveElement) {
			oldActiveElement.style.position = 'absolute';
			oldActiveElement.style.top = 0;
			oldActiveElement.style.right = 0;
			oldActiveElement.style.bottom = 0;
			oldActiveElement.style.left = 0;

			newActiveElement.style.position = '';
			newActiveElement.style.top = '';
			newActiveElement.style.right = '';
			newActiveElement.style.bottom = '';
			newActiveElement.style.left = '';

			wrapperElement.style.height = `${ oldActiveElement.offsetHeight }px`;
		}

		previousStageRef.current = newActiveElement;

		wrapperElement.style.height = `${ newActiveElement.offsetHeight }px`;

		return () => wrapperElement.removeEventListener('transitionend', handleTransitionEnd);
	}, [active]);

	return (
		<div
			ref={ wrapperRef }
			style={
				{
					overflow: 'hidden',
					position: 'relative',
					transition: 'height 1s ease-in-out',
				}
			}
		>
			{
				Object
					.keys(stages)
					.map((key) => {
						const stage = stages[key];

						return (
							<div
								key={ key }
								ref={ element => (stageRefs.current[key] = element) }
								style={
									{
										opacity: key === active ? 1 : 0,
										transition: 'opacity 300ms ease-in-out',
										display: 'flex',
										flexDirection: 'column',
									}
								}
							>
								{ stage }
							</div>
						);
					})
			}
		</div>
	);
}
