import {
	Modal,
} from 'modal-legacy';

import {
	useClearDemoDataStep,
	useDpaStep,
	useStepper,
	useTotalSteps,
} from './hooks';

export function ClearDemoDataModal(props) {
	const {
		isOpen,
		onClose,
	} = props;

	const totalSteps = useTotalSteps(isOpen);
	const stepper = useStepper(totalSteps, {
		onComplete: () => window.location.reload(),
	});

	const clearDemoDataStep = useClearDemoDataStep(1, stepper);
	const dpaStep = useDpaStep(2, stepper);

	const steps = [clearDemoDataStep, dpaStep];

	if (totalSteps === 0) {
		return null;
	}

	return (
		<Modal
			isOpen={ isOpen }
			onClose={ onClose }
			{ ...steps[stepper.currentStep - 1] }
		/>
	);
}
