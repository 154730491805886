import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { DpaStatus, Modals } from './constants';
import { DPA_UPDATE_POSTPONED_ON } from 'data-processing-agreement.mod';

export function useCurrentModal(defaultModal, currentDpa) {
	const [currentModal, setCurrentModal] = useState();

	useEffect(() => {
		if (currentDpa) {
			const lastUpdated = moment(currentDpa.statusUpdatedDate);
			const isWithinTimeFrame = moment().isBefore(lastUpdated.add(1, 'weeks'));

			if (currentDpa.status === DpaStatus.NEEDS_TO_SIGN) {
				setCurrentModal(Modals.REQUESTED_DPA_MODAL);
			} else if (
				currentDpa.status === DpaStatus.NEEDS_PROMPT ||
				(currentDpa.status === DpaStatus.COMPLETE_LATER && !isWithinTimeFrame)
			) {
				setCurrentModal(Modals.CONDITIONAL_DPA_MODAL);
			} else if (currentDpa.status === DpaStatus.UPDATE_PENDING) {
				const postponedDate = localStorage.getItem(DPA_UPDATE_POSTPONED_ON);
				// If the user has not postponed the update, show the update pending modal
				if (!postponedDate) {
					setCurrentModal(Modals.UPDATED_DPA_MODAL);
					// If the user has postponed the update, show after 7 days
				} else if (DateTime.fromISO(postponedDate).diffNow('days').days < -7) {
					setCurrentModal(Modals.UPDATED_DPA_MODAL);
				}
			}
		}
	}, [currentDpa]);

	useEffect(() => {
		if (defaultModal && !currentModal) {
			setCurrentModal(defaultModal);
		}
	}, [defaultModal]);

	return {
		closeModal: () => setCurrentModal(null),
		currentModal,
	};
}
