import Ctrl, {
	onReady,
} from 'ctrl.deco';
import {
	isInvalidLogin,
	handleFlip,
	handleForgotPasswordSubmit,
} from './domain';
import { removeBannerLocalStorage } from 'setup-summary-banner.mod/utils';
import { render } from 'base/wrapped-render';
import React from 'react';
import { LoginPassword } from './LoginInputs/PasswordInput/login-password'
const loginFormSelector = 'form[name="loginform"]';

@Ctrl([
	'/login*',
	'/auth/login*',
	'/payroll/(domain|login)*',
	'/self_onboarding/login.php*',
])
export class LoginCtrl {


	@onReady()
	_onReady(event: Event, ctrl: LoginCtrl): void {
		removeBannerLocalStorage();

		const form = document.querySelector<HTMLFormElement>(loginFormSelector);
		const flipContainer = document.querySelector<HTMLDivElement>('.flip-container');
		ctrl.attachEventListeners(form, flipContainer);
		if (form) {
			ctrl.focusEmailInput(form);
		}

		render(<LoginPassword />, document.getElementById('js-password_input_container_login'));

	}

	attachEmailLoginLinkListener(form: HTMLFormElement): void {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const ctrl: LoginCtrl = this;
		const loginLink = form.querySelector('.js-normalLoginLink');
		if (!loginLink) {
			return;
		}
		loginLink.addEventListener('click', (event: Event) => {
			event.preventDefault();
			form.addEventListener('transitionend', handleTransitionEnd);
			form.classList.add('show-normal-login');
		});

		function handleTransitionEnd(): void {
			form.removeEventListener('transitionend', handleTransitionEnd);
			ctrl.focusEmailInput(form);
		}
	}

	attachEventListeners(form: HTMLFormElement, flipContainer: HTMLDivElement): void {
		const isResellerLoginPage = !!document.querySelector('.reseller-login-page');
		if (form && !isResellerLoginPage) {
			this.attachFormSubmitListener(form);
			this.attachEmailLoginLinkListener(form);
		}
		if (flipContainer) {
			this.attachForgotPasswordFlipListener(flipContainer);
			this.attachForgotPasswordSubmit(flipContainer, isResellerLoginPage);
		}
	}

	attachForgotPasswordFlipListener(flipContainer: HTMLDivElement): void {
		document.addEventListener('click', (event: Event): void => {
			const el = event.target as HTMLElement;
			if (!el.classList.contains('forgot')) {
				return;
			}
			event.preventDefault();
			const forgotButton = event.target as HTMLButtonElement;
			handleFlip(flipContainer, forgotButton);
		});
	}

	attachForgotPasswordSubmit(flipContainer: HTMLDivElement, isResellerLoginPage: boolean): void {
		document.addEventListener('click', handleForgotPasswordSubmit(flipContainer, isResellerLoginPage));
		document.addEventListener('keydown', handleForgotPasswordSubmit(flipContainer, isResellerLoginPage));
	}

	attachFormSubmitListener(form: HTMLFormElement): void {
		form.addEventListener('submit', (event) => {
			const emailVal = form.querySelector<HTMLInputElement>('#lemail')?.value || '';
			const passwordVal = form.querySelector<HTMLInputElement>('#password')?.value || '';
			if (!isInvalidLogin(emailVal, passwordVal)) {
				event.preventDefault();
				return false;
			}
		});
	}

	focusEmailInput(form: HTMLFormElement): void {
		const loginEmailInput = form.querySelector<HTMLInputElement>('#lemail');
		if (!loginEmailInput) {
			return;
		}
		loginEmailInput.focus();
	}
}
