/* @startCleanup encore #deleteFile */
import classNames from 'classnames';
import { Icon } from '@bamboohr/fabric';

import './styles.styl';

export function Headline(props) {
	const {
		hasSeparator = true,
		icon,
		children,
	} = props;

	return (
		<div
			className={
				classNames('CompanyDataModalHeadline', {
					'CompanyDataModalHeadline--hasSeparator': hasSeparator,
				})
			}
		>
			<Icon
				brand={ true }
				name={ icon }
			/>

			{
				children ? (
					<h3>{ children }</h3>
				) : null
			}
		</div>
	);
}
/* @endCleanup encore */
