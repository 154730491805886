import { Fragment } from 'react';

import { useCurrentDpa, ConditionalDpaModal, RequestedDpaModal, UpdatedDPAModal } from 'data-processing-agreement.mod';

import { ClearDemoDataModal } from './components/clear-demo-data-modal.react';
import { Modals } from './constants';
import { useCurrentModal } from './hooks';

export function CompanyData(props) {
	const { defaultModal, onDefaultModalClose } = props;

	const { currentDpa } = useCurrentDpa();

	const { currentModal, closeModal } = useCurrentModal(defaultModal, currentDpa);

	const _closeModal = () => {
		if (currentModal === defaultModal) {
			onDefaultModalClose();
		}
		closeModal();
	};

	return (
		<Fragment>
			<ClearDemoDataModal agreement={currentDpa} isOpen={currentModal === Modals.CLEAR_DEMO_DATA_MODAL} onClose={_closeModal} />
			<RequestedDpaModal agreement={currentDpa} isOpen={currentModal === Modals.REQUESTED_DPA_MODAL} onClose={_closeModal} />
			<ConditionalDpaModal agreement={currentDpa} isOpen={currentModal === Modals.CONDITIONAL_DPA_MODAL} onClose={_closeModal} />
			<UpdatedDPAModal agreement={currentDpa} isOpen={currentModal === Modals.UPDATED_DPA_MODAL} onClose={_closeModal} />
		</Fragment>
	);
}
